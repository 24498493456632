import { Organization } from '@eppendorf/vnls-user-tenant-utils';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { getLocalizedDateFromUtcString } from '$shared/utils/date.utils';
import { getFullname } from '$shared/utils/get-fullname.util';

import './organization-detail.scss';
import { OrganizationDetailRowSendInvite } from '$features/organizations/detail/row/organization-detail-row-send-invite';
import {
  OrganizationDetailRowSupportAccount
} from '$features/organizations/detail/row/organization-detail-row-support-account';

import { OrganizationDetailRow } from './row/organization-detail-row';


interface Props {
  organization: Organization;
}

export function OrganizationDetail({ organization }: Props): ReactElement {
  const {
    id,
    name,
    contactFirstName,
    contactLastName,
    contactEmail,
    adminFirstName,
    adminLastName,
    adminEmail,
    createdBy,
    createdAt,
  } = organization;

  const { t } = useTranslation();

  return (
    <div className="organization-details-content">
      <OrganizationDetailRow label={t('shared.id')} value={id} />

      <OrganizationDetailRow label={t('organizationDetails.name')} value={name} />

      <OrganizationDetailRow
        label={t('organizationDetails.type')}
        value={organization.type ?? '-'}
      />

      <OrganizationDetailRow
        label={t('organizationDetails.subscription')}
        value={organization.subscription ?? '-'}
      />

      <OrganizationDetailRow
        label={t('organizationDetails.subscribedDevices')}
        value={organization.subscribedDevices?.toString() ?? '-'}
      />

      <OrganizationDetailRow
        label={t('organizationDetails.contactName')}
        value={getFullname(contactFirstName, contactLastName)}
      />

      <OrganizationDetailRow
        label={t('organizationDetails.contactEmail')}
        value={contactEmail}
      />

      <OrganizationDetailRow
        label={t('organizationDetails.adminName')}
        value={getFullname(adminFirstName, adminLastName)}
      />

      <OrganizationDetailRow
        label={t('organizationDetails.adminEmail')}
        value={adminEmail}
      />

      <OrganizationDetailRow
        label={t('shared.created')}
        value={`${t('shared.by')} ${createdBy} ${t('shared.on')} ${getLocalizedDateFromUtcString(createdAt)}`}
      />

      <OrganizationDetailRowSupportAccount id={id} />

      <OrganizationDetailRowSendInvite organization={organization} />
    </div>
  );
}
