import {
  Button,
  DotsLoader,
  Sidecar,
} from '@eppendorf/vnls-react-components';
import { AxiosError } from 'axios';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useBlocker, useSearchParams } from 'react-router-dom';

import { ConfirmUnsavedChangesDialog } from '$components/confirm-unsaved-changes-dialog';
import { LoadingErrorHint } from '$components/loading-error-hint/loading-error-hint';
import { useSidecar } from '$components/sidecar-router/use-sidecar.hook';
import { OrganizationDetail } from '$features/organizations/detail';
import OrganizationActions from '$features/organizations/detail/actions/actions';
import { InvitationStatus } from '$features/organizations/detail/invitation-status';
import {
  OrganizationForm,
  OrganizationFormId,
  OrganizationFormMappers,
  OrganizationFormProfile,
} from '$features/organizations/form';
import {
  useGetOrganization,
  useUpdateOrganization,
} from '$features/organizations/organizations.api';

import { SIDECAR_ROUTE_PARAMS } from 'src/sidecar-route-params';

export function OrganizationDetailPage() {
  const { t } = useTranslation();
  const [search] = useSearchParams();
  const { isSidecarOpen, handleSidecarOpenChange } = useSidecar();
  const [isEditMode, setEditMode] = useState(false);
  const [showSaveUnchangedValues, setShowSaveUnchangedValues] = useState(false);
  const subscriptionNames = ['Essentials', 'SampleSafetyPlus'];
  const typeNames = ['Academia', 'Industry'];

  const { mutateAsync, isLoading } = useUpdateOrganization();

  const organizationForm = useForm<OrganizationFormProfile>();

  const {
    handleSubmit,
    formState: { isDirty },
    reset,
  } = organizationForm;

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      isDirty &&
      (currentLocation.pathname !== nextLocation.pathname ||
        currentLocation.search !== nextLocation.search),
  );

  const { data: organization, isError, isLoading: isLoadingOrganization } = useGetOrganization(
    search.get(SIDECAR_ROUTE_PARAMS.organization.id) || '',
  );

  const handleToggleEdit = () => {
    if (isEditMode && isDirty) {
      setShowSaveUnchangedValues(true);
      return;
    }

    setEditMode(!isEditMode);
  };

  const handleEditOrganization = async (formValues: OrganizationFormProfile) => {
    if (!organization) return;

    try {
      await mutateAsync(
        OrganizationFormMappers.mapFormValuesToUpdateRequest(
          organization?.id,
          formValues,
        ),
      );
      setEditMode(false);
      setShowSaveUnchangedValues(false);
      reset();
    } catch (error) {
      if (error instanceof AxiosError && error.response?.status === 409) {
        organizationForm.setError('adminEmail', {
          type: 'conflict',
          message: t('addOrganization.adminUserAlreadyExists'),
        });
      }
    }
  };

  const handleSaveChanges = () => {
    handleSubmit(async (formValues) => {
      await handleEditOrganization(formValues);
    })();

    blocker.proceed?.();

    setShowSaveUnchangedValues(false);
  };

  const handleDiscardChanges = () => {
    setEditMode(false);
    blocker.proceed?.();
    setShowSaveUnchangedValues(false);
    reset();
  };

  const handleSidecarStateChange = () => {
    if (isEditMode && isDirty) {
      setShowSaveUnchangedValues(true);
      return;
    }

    handleSidecarOpenChange();
  };

  return (
    <Sidecar
      isOpen={isSidecarOpen}
      onOpenChange={handleSidecarStateChange}
    >
      {isLoadingOrganization && <DotsLoader />}

      {isError && <LoadingErrorHint />}

      {organization && (
        <div className="organization-detail-header flex flex__ai--center m-bottom-xxl" style={{ maxWidth: '80%' }}>
          <h2 className="title m-right-s" style={{ maxWidth: '100%', wordWrap: 'break-word' }}>{organization.name}</h2>

          <InvitationStatus bordered status={organization.adminStatus} />
        </div>
      )}

      {organization && (
        <div className="box">

          {isEditMode ? (
            <>
              {/* eslint-disable-next-line react/jsx-props-no-spreading -- FormProvider does not privde a prop to give the whole form context */}
              <FormProvider {...organizationForm}>
                <OrganizationForm
                  subscriptions={subscriptionNames}
                  types={typeNames}
                  onSubmit={handleEditOrganization}
                  organization={organization}
                />
              </FormProvider>

              <div className="flex flex__jc--flex-end" id="org-detail-edit-button">
                <Button disabled={isLoading} variant="secondary" onClick={handleToggleEdit}>
                  {t('shared.cancel')}
                </Button>
                <Button
                  type="submit"
                  className="m-left-l btn--solid"
                  formId={OrganizationFormId}
                  disabled={isLoading || !isDirty}
                >
                  {t('shared.save')}
                </Button>
              </div>
            </>
          ) : (
            <>
              <OrganizationDetail organization={organization} />
              <OrganizationActions organization={organization} onEditPress={handleToggleEdit} />
            </>
          )}

          <ConfirmUnsavedChangesDialog
            isOpen={blocker.state === 'blocked' || showSaveUnchangedValues}
            onSave={handleSaveChanges}
            onDiscard={handleDiscardChanges}
          />
        </div>
      )}
    </Sidecar>
  );
}
