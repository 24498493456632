import './organization-detail-row.scss';
import './organization-detail-row-send-invite.scss';
import { Button, Icon, IconSizeClasses } from '@eppendorf/vnls-react-components';
import { InvitationStatus, Organization } from '@eppendorf/vnls-user-tenant-utils';
import { useTranslation } from 'react-i18next';

import { getLocalizedDateFromUtcString } from '$shared/utils/date.utils';

import { useResendInvite } from '$features/organizations';

interface Props {
  organization: Organization;
}

export function OrganizationDetailRowSendInvite({ organization }: Props) {
  const { t } = useTranslation();

  const {
    mutateAsync: resendInviteAsync,
    isError,
    isLoading,
    isSuccess: inviteSendSuccessFully,
  } = useResendInvite();

  async function handleInviteResend(): Promise<void> {
    try {
      await resendInviteAsync(organization.id);
    } catch {
      // eslint-disable-next-line no-useless-return -- disables test error output
      return;
    }
  }

  return (
    <div className="label-value-row">
      <p className="label">{t('organizationDetails.inviteSent')}</p>

      <div className="value">
        {inviteSendSuccessFully ? (
          <div className="resend-successfully-container">
            <Icon
              name="success"
              size={IconSizeClasses.XSmall}
              className="bg-green-500 flex m-right-s"
            />

            <p className="color-green-500 flex">
              {t('organizationDetails.inviteSentSuccess')}
            </p>
          </div>
        ) : (
          <p>
            {organization.inviteSentAt
              ? getLocalizedDateFromUtcString(organization.inviteSentAt)
              : 'Never'}
          </p>
        )}

        {(organization.adminStatus === InvitationStatus.Invited ||
          organization.adminStatus === InvitationStatus.Migrated) && (
          <div className="label-value-row resend-invite-row">
            <Button
              variant="tertiary"
              size="small"
              onClick={() => handleInviteResend()}
              disabled={isLoading || isError}
            >
              {t('organizationDetails.resendInvite')}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
