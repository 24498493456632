import { Button, Dialog, Icon, IconSizeClasses } from '@eppendorf/vnls-react-components';
import { Organization, InvitationStatus as AdminStatus } from '@eppendorf/vnls-user-tenant-utils';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import {
  downloadMigrationReport,
  useInviteUsers,
  useMigrateOrganizations
} from '$features/organizations';

export default function OrganizationActions ({ organization, onEditPress }: { organization: Organization, onEditPress: () => void }) {
  const { t } = useTranslation();
  const migrateOrganizationMutation = useMigrateOrganizations();
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const { mutateAsync: inviteUsers, isLoading: isInviteUsersLoading, isSuccess: isInvitationSent, isError: isInviteUsersError } = useInviteUsers();

  const isMigratedOrganization = !!organization?.legacyTenantId;
  const showInviteUsersButton = isMigratedOrganization && (organization.adminStatus === AdminStatus.Active || organization.adminStatus === AdminStatus.Invited);
  const showMigrateOrganizationButton = isMigratedOrganization && organization.adminStatus === AdminStatus.Imported;
  const showDownloadMigrationReportButton = isMigratedOrganization && organization.adminStatus !== AdminStatus.Imported;
  const disabledInviteUsersButton = (organization.adminStatus !== AdminStatus.Active) || isInviteUsersLoading;

  const [isDownloading, setIsDownloading] = useState(false);

  const handleDownloadReport = async () => {
    setIsDownloading(true);

    const { url } = await downloadMigrationReport(organization?.legacyTenantId || '');
    if(url === null) {
      toast("Unable to download report. Try again later.", { type: 'error' });
      setIsDownloading(false);
      return;
    }
    window.open(url, '_blank');

    setIsDownloading(false);
  };

  const handleInviteUsers = async (tenantId: string) => {
    setConfirmationDialogOpen(false);
    await inviteUsers(tenantId);
  };

  useEffect(() => {
    if(migrateOrganizationMutation.isError || isInviteUsersError) {
      const message  = migrateOrganizationMutation.error?.message || "Unable to perform action. Try again later.";

      toast(message, { type: 'error' });
    }
  }, [migrateOrganizationMutation.isError, isInviteUsersError]);

  useEffect(() => {
    if(isInvitationSent || migrateOrganizationMutation.isSuccess) {
      toast("Success !", { type: 'success' });
    }
  }, [isInvitationSent, migrateOrganizationMutation.isSuccess]);

  return (
    <>
      <div className={`flex flex__jc--${isMigratedOrganization ? 'space-between' : 'flex-end'} m-top-m`}>
        {
          showInviteUsersButton && (
            <div data-e2e-id="resend-user-invite-button">
              <Button
                disabled={disabledInviteUsersButton}
                onClick={() => setConfirmationDialogOpen(true)}
              >
                <Icon
                  name="mail"
                  size={IconSizeClasses.Small}
                  className={disabledInviteUsersButton ? 'bg-gray-400' : 'bg-white'}
                />
                {
                  isInviteUsersLoading
                    ? t('organizationDetails.inviting')
                    : t('organizationDetails.inviteUsers')
                }
              </Button>
            </div>
          )
        }

        {
          showMigrateOrganizationButton && (
            <div data-e2e-id="resend-user-invite-button">
              <Button
                onClick={() => migrateOrganizationMutation.mutateAsync(organization?.legacyTenantId || '')}
                disabled={migrateOrganizationMutation.isLoading}
              >
                {
                  migrateOrganizationMutation.isLoading
                    ? t('organizationDetails.migrating')
                    : t('organizationDetails.startMigration')
                }
              </Button>
            </div>
          )
        }

        {
          showDownloadMigrationReportButton && (
            <div data-e2e-id="download-migration-report-button">
              <Button
                disabled={isDownloading}
                onClick={handleDownloadReport}
              >
                <Icon
                  name="export"
                  size={IconSizeClasses.XSmall}
                  className="bg-white m-right-s"
                />
                {
                  isDownloading
                    ? t('shared.downloading')
                    : t('organizationDetails.downloadReport')}
              </Button>
            </div>
          )
        }

        <div data-e2e-id="edit-organization-button">
          <Button onClick={onEditPress}>
            <Icon
              name="edit"
              size={IconSizeClasses.XSmall}
              className="bg-white m-right-xs"
            />
            {t('shared.edit')}
          </Button>
        </div>
      </div>

      <Dialog isOpen={confirmationDialogOpen} hideCloseButton>
        <div className="flex flex__dir--column flex__ai--center">
          <Icon
            name="warning"
            className="bg-orange-500 m-bottom-xxl"
            size={IconSizeClasses.XLarge}
          />

          <div className="title">{t('inviteUsers.title')}</div>

          <div className="body-regular">{t('inviteUsers.message')}</div>
        </div>

        <Dialog.Actions>
          <Button
            onClick={() => setConfirmationDialogOpen(false)}
            variant="secondary"
          >
            {t('inviteUsers.cancel')}
          </Button>
          <Button
            onClick={() => handleInviteUsers(organization.legacyTenantId || '')}
            variant="primary"
          >
            {t('inviteUsers.confirm')}
          </Button>
        </Dialog.Actions>
      </Dialog>
    </>
  );
}
